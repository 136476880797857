import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../../styles/utilities';

const commonButtonStyles = () => {
  return css`
    display: block;
    padding: ${({ alt }) => (alt ? '6px 10px' : '12px 20px')};
    color: ${standardColours.white};
    font-weight: ${fontWeights.semibold};
    ${fontSize(14)};
    line-height: 1.5;
    text-align: center;
    background-color: ${({ alt }) =>
      alt ? brandColours.primary : brandColours.primary};
    border-radius: 22px;
  `;
};

const StyledButton = styled.button`
  ${commonButtonStyles()};
  font-family: ${brandFonts.primary};
  width: 100%;
  border: none;
  cursor: pointer;
`;

const StyledButtonLink = styled(Link)`
  ${commonButtonStyles()};
`;

export const Button = ({ children, ...props }) => {
  const ButtonComponent = props.to ? StyledButtonLink : StyledButton;

  return <ButtonComponent {...props}>{children}</ButtonComponent>;
};
