import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../styles/utilities';

const StyledText = styled.p`
  margin: 20px 0;
  ${fontSize(13)};
  line-height: 1.6;
  text-align: center;

  a {
    text-decoration: underline;
  }
`;

export const Text = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>;
};
