import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
} from '../styles/utilities';
import { Svg } from './ui';
import personIcon from '../images/person-icon.svg';
import searchIcon from '../images/search-icon.svg';
import shareIcon from '../images/share-icon.svg';
import menuIcon from '../images/menu-icon.svg';

const StyledNav = styled.nav``;

const StyledNavList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px;
`;

const StyledNavItem = styled.li``;

const commonNavItemStyles = () => {
  return css`
    display: block;
    padding: 10px;
    color: ${brandColours.secondary};
    font-weight: ${fontWeights.semibold};
    text-align: center;
  `;
};

const StyledNavItemLink = styled(Link)`
  ${commonNavItemStyles()};
`;

const StyledNavItemButton = styled.button`
  ${commonNavItemStyles()};
  font-family: ${brandFonts.primary};
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledNavItemIcon = styled(Svg)`
  height: 30px;
  width: 30px;
`;

const StyledNavItemText = styled.span`
  display: block;
  margin-top: 4px;
  ${fontSize(9)};
`;

const StyledSubNavList = styled.ul`
  position: absolute;
  bottom: 100%;
  left: 0;
  display: ${({ subNavDisplay }) => (subNavDisplay ? 'block' : 'none')};
  width: 100%;
  background-color: ${brandColours.primary};
`;

const StyledSubNavItem = styled.li`
  border-top: 1px solid ${standardColours.white};

  &:first-child {
    border-top: 0;
  }
`;

const StyledSubNavItemLink = styled.a`
  display: block;
  padding: 15px 20px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.semibold};
`;

const Navigation = () => {
  const [subNavDisplay, setSubNavDisplay] = useState(false);

  const items = [
    {
      props: {
        to: '/goals',
      },
      icon: personIcon,
      text: 'Goals',
    },
    {
      props: {
        to: '/search',
      },
      icon: searchIcon,
      text: 'Search',
    },
    {
      props: {
        onClick: () => {
          if (navigator.share) {
            navigator.share({
              title: document.title,
              text: `${document.title} on Impact Score.`,
              url: 'https://www.impactscore.app/shared-with-me/',
            });
          } else {
            window.location.href = `mailto:?&subject=${document.title} on Impact Score&body=Check out the score for ${document.title} on Impact Score, https://www.impactscore.app/share-landing-page/.`;
          }
        },
      },
      icon: shareIcon,
      text: 'Share Page',
    },
    {
      props: {
        subNavDisplay: subNavDisplay,
        onClick: () => setSubNavDisplay(!subNavDisplay),
      },
      icon: menuIcon,
      text: 'Menu',
      childItems: [
        {
          props: {
            href: 'https://www.impactscore.app/contact/',
            target: '_blank',
            rel: 'noopener',
          },
          text: 'Contact',
        },
        {
          props: {
            href: 'https://www.impactscore.app/faqs/',
            target: '_blank',
            rel: 'noopener',
          },
          text: 'FAQs',
        },
        {
          props: {
            href: 'https://www.impactscore.app/privacy-policy/',
            target: '_blank',
            rel: 'noopener',
          },
          text: 'Legal stuff',
        },
      ],
    },
  ];

  return (
    <StyledNav>
      <StyledNavList>
        {items.map(({ props, icon, text, childItems }, i) => {
          const NavItemComponent = props.to
            ? StyledNavItemLink
            : StyledNavItemButton;

          return (
            <StyledNavItem key={`nav-item-${i}`}>
              <NavItemComponent {...props}>
                <StyledNavItemIcon image={icon} />
                <StyledNavItemText>{text}</StyledNavItemText>
              </NavItemComponent>
              {childItems && (
                <StyledSubNavList subNavDisplay={subNavDisplay}>
                  {childItems.map(({ props, text }, i) => (
                    <StyledSubNavItem key={`sub-nav-item-${i}`}>
                      <StyledSubNavItemLink {...props}>
                        {text}
                      </StyledSubNavItemLink>
                    </StyledSubNavItem>
                  ))}
                </StyledSubNavList>
              )}
            </StyledNavItem>
          );
        })}
      </StyledNavList>
    </StyledNav>
  );
};

export default Navigation;
