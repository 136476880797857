import React from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  zIndexLayers,
} from '../styles/utilities';
import Navigation from './Navigation';

const StyledFooter = styled.footer`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndexLayers.fourth};
  background-color: ${standardColours.white};
  border-top: 1px solid ${brandColours.tertiary};
`;

const Footer = () => (
  <StyledFooter>
    <Navigation />
  </StyledFooter>
);

export default Footer;
