export const standardColours = {
  black: '#000',
  white: '#FFF',
  red: '#CC4A4A',
  amber: '#F39653',
  green: '#84C796',
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  primary: '#F37D5A', // Dark Orange
  secondary: '#092838', // Dark Blue
  tertiary: '#FAF4EF', // light grey
  quaternary: '#523A79', // Purple
};

export const scoreColours = {
  low: standardColours.red,
  medium: standardColours.amber,
  high: standardColours.green,
};
