import React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyle from '../styles/GlobalStyle';
import { Container } from './ui';
import Footer from './Footer';

const Layout = ({ isHomepage, isPreferencesPage, children }) => {
  return (
    <>
      <Helmet>
        <html lang="en-GB" />
      </Helmet>
      <GlobalStyle
        isHomepage={isHomepage}
        isPreferencesPage={isPreferencesPage}
      />
      <Container>{children}</Container>
      {!isHomepage && !isPreferencesPage && <Footer />}
    </>
  );
};

export default Layout;
