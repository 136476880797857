import { createGlobalStyle, css } from 'styled-components';
import banksMilesSingleLineWoff2 from '../fonts/banksmilessingleline.woff2';
import banksMilesSingleLineWoff from '../fonts/banksmilessingleline.woff';
import { brandColours, brandFonts, fontWeights, fontSize } from './utilities';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Banks Miles Single Line';
    src: url('${banksMilesSingleLineWoff2}') format('woff2'),
         url('${banksMilesSingleLineWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    height: -webkit-fill-available;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 90vh;
    color: ${brandColours.secondary};
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.medium};
    ${fontSize(16)}
    line-height: 1.3;
    background: ${brandColours.tertiary};

    ${({ isHomepage }) => {
      if (isHomepage) {
        return css`
          display: flex;
          justify-content: center;
          align-items: center;
        `;
      }
    }}

    ${({ isPreferencesPage }) => {
      if (!isPreferencesPage) {
        return css`
          padding-bottom: 80px;
        `;
      }
    }}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    height: auto;
    width: auto;
    vertical-align: middle;
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
  }
`;

export default GlobalStyle;
