import React from 'react';
import styled from 'styled-components';
import { fontSize, fontWeights } from '../../styles/utilities';

const StyledHeading = styled.h1`
  margin: 20px 0;
  ${fontSize(24)};
  font-weight: ${fontWeights.semibold};
  text-align: center;
`;

export const Heading = ({ children, ...props }) => {
  return <StyledHeading {...props}>{children}</StyledHeading>;
};
