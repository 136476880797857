import { css } from 'styled-components';

export const breakpointSizes = {
  tiny: '360',
  tsmall: '520',
  small: '620',
  smedium: '768',
  medium: '870',
  mlarge: '980',
  large: '1120',
  xlarge: '1260',
  xxlarge: '1400',
  xxxlarge: '1600',
  xxxxlarge: '2000',
};

export const minBreakpointQuery = Object.keys(breakpointSizes).reduce(
  (acc, size) => {
    acc[size] = (...args) => css`
      @media (min-width: ${breakpointSizes[size]}px) {
        ${css(...args)}
      }
    `;
    return acc;
  },
  {}
);

export const maxBreakpointQuery = Object.keys(breakpointSizes).reduce(
  (acc, size) => {
    acc[size] = (...args) => css`
      @media (max-width: ${breakpointSizes[size] - 1}px) {
        ${css(...args)}
      }
    `;
    return acc;
  },
  {}
);
