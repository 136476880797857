import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1000px;
`;

export const Container = ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
);
